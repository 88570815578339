import React from 'react';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { Fade } from 'react-awesome-reveal';
import { graphql } from 'gatsby';
import Layout from 'layouts/Clean';
import {
  Title,
  Text,
  Scrollable,
  HorizontalScrollingLoop,
  Hero,
  SEO,
  TShapedBlock,
  SplitTextBlock,
  Button,
  SecretsBlock,
  ImageBlock,
} from 'components';
import heroImg from '../images/innovazione/28-lucchesi-innovazione.jpg';
import img29 from '../images/innovazione/29-lucchesi-innovazione.jpg';
import img30 from '../images/innovazione/30-lucchesi-innovazione.jpg';
import img31 from '../images/innovazione/31-lucchesi-innovazione.jpg';
import img32 from '../images/innovazione/32-lucchesi-innovazione.jpg';
import img33 from '../images/innovazione/33-lucchesi-innovazione.jpg';
import img34 from '../images/innovazione/34-lucchesi-innovazione.jpg';
import img35 from '../images/innovazione/35-lucchesi-innovazione.jpg';
import img36 from '../images/innovazione/36-lucchesi-innovazione.jpg';
import img37 from '../images/innovazione/37-lucchesi-innovazione.jpg';
import img38 from '../images/innovazione/38-lucchesi-innovazione.jpg';

import img05 from '../images/azienda/05-matteo-lucchesi.jpg';
import img06 from '../images/azienda/06-niccolo-lucchesi.jpg';
import img07 from '../images/azienda/07-lucchesi-azienda.jpg';
import img11 from '../images/azienda/11-lucchesi-azienda.jpg';

const InnovazionePage = ({ location }) => {
  const { t } = useTranslation();
  // const { crumbs } = useBreadcrumb({
  //   location,
  //   crumbLabel: t('Innovazione'),
  //   crumbSeparator: ' > ',
  // });

  const textileImages = [img34, img35, img36];

  const PAGE_PROPERTIES = {
    title: (
      <Trans>
        Sei mai entrato in un <font className="text-secondary">museo</font> per lavorare alla tua{' '}
        <font className="text-secondary">prossima collezione?</font>
      </Trans>
    ),
    image: heroImg,
    description: (
      <Trans>
        Nella nostra sede di Prato conserviamo oltre 100 anni di collezioni, all’ interno di un
        archivio privato esclusivo per ubicazione, contenuti, immensa varietà di articoli e capi
        presenti.
      </Trans>
    ),
  };

  // const teamImages = [img04, img05, img06];

  return (
    <Layout location={location}>
      <SEO
        keywords={['Vivere in tessuto', 'Lucchesi']}
        title={t("Ricerca e sviluppo tessile da sempre.")}
        description={t("Facciamo quello che gli altri non fanno. Pensiamo e creiamo collezioni innovative che stravolgeranno la tua collezione")}
        location={location}
      />
      <section className="mt-12 md:mt-40">
        <Hero invert {...PAGE_PROPERTIES}></Hero>
      </section>
      <section className="my-24 px-12 md:px-60 min-h-[50vh] flex flex-col justify-center items-center relative">
        <div className="z-20">
          <Trans>
            <Title>
              NON UN MUSEO MA <font className="text-secondary">UNA STORIA</font> CHE CONTINUA AD
              ISPIRARE LA RICERCA DI <font className="text-secondary">NUOVI TESSUTI</font>
            </Title>
          </Trans>
        </div>

        <div className="absolute w-40 md:w-60 h-auto -bottom-32 right-0 z-10">
          <img className="w-full h-full object-cover" src={img30}></img>
        </div>
        <div className="absolute w-60 h-auto -top-28 md:top-0 left-0 md:left-16 z-10">
          <img className="w-full h-full object-cover" src={img29}></img>
        </div>
      </section>
      <section className="my-24">
        <TShapedBlock
          title={t('Quello che gli altri non fanno')}
          firstDesc={t(
            'Ciò che ci contraddistingue è la fusione di creatività e ingegno, nella ricerca di qualcosa di nuovo e raffinato, con il massimo rigore per la produzione e la consegna dei tessuti.',
          )}
          secondDesc={t(
            'Facciamo quello che gli altri non fanno: non perchè siamo i più bravi, ma perchè diamo forma a qualcosa che potrete trovare solo da noi.',
          )}
          image={img31}></TShapedBlock>
      </section>
      <section className="my-24 flex flex-row px-12 md:px-0 relative">
        <div className="hidden md:flex md:flex-1">
          <img src={img32} className="w-2/3 h-[80vh] object-cover"></img>
        </div>
        <div className="flex md:hidden flex-1 absolute -top-40 left-0">
          <img src={img32} className="w-32 h-[80vh] object-cover"></img>
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex flex-col pr-24 my-8">
            <Title>{t('Attività di ricerca')}</Title>
            <Text>
              {t('Lo stile è ciclico.')} <br />
              {t('Ce lo insegnano i capi della nostra collezione.')} <br />
              {t('Ce lo insegna la storia della nostra città.')} <br />
              {t(
                'Ma nessun tessuto e nessun indumento sarà mai riproposto tale e quale a come è stato.',
              )}
              <br />
              {t(
                'Il nostro “museo” è un punto di partenza per trovare nuove ispirazioni e che potrai trovare solo qui da noi in tutto il mondo.',
              )}
            </Text>
          </div>
          <div className="flex flex-col pr-24 my-8">
            <Title>{t('Laboratorio di sperimentazione')}</Title>
            <Text>
              {t(
                'Ogni stagione le nostre collezioni si arricchiscono di novità tecniche legate alla tipologia di fibre impiegate, alla creatività dei tessuti proposti, ai finissaggi che soltanto noi siamo in grado di realizzare.',
              )}
              <br />
              {t(
                'Possiamo proporre attingendo ai nostri campioni o sviluppare sulla base della necessità del cliente.',
              )}
            </Text>
          </div>
        </div>
      </section>

      <section className="h-screen w-screen relative flex flex-row items-center justify-center">
        <ImageBlock
          label={t('PROCESSO CREATIVO')}
          text={t(
            'I dettagli fanno la differenza tra qualcosa di già visto e qualcosa di rivoluzionario',
          )}
          image={img33}></ImageBlock>
      </section>
      <section className="my-12">
        <HorizontalScrollingLoop
          firstLine={
            <Trans>
              Un <font className="text-secondary">macrocosmo</font> fatto di cultura, passione,
              innovazione, <font className="text-secondary">archeologia industriale</font>, impegno,
              tecnica e raffinatezza.
            </Trans>
          }
          secondLine={
            <Trans>
              Un <font className="text-secondary">macrocosmo</font> fatto di cultura, passione,
              innovazione, <font className="text-secondary">archeologia industriale</font>, impegno,
              tecnica e raffinatezza.
            </Trans>
          }
        />
      </section>
      <section className="my-24">
        <Scrollable images={textileImages}></Scrollable>
      </section>
      <section className="h-screen w-screen relative flex flex-row items-center justify-center">
        <ImageBlock
          label={t('LA NOSTRA FILOSOFIA')}
          text={t(
            'IL PASSATO E IL FUTURO SI INTRECCIANO NEI NOSTRI TESSUTI PER DAR VITA A QUALCOSA DI STRAORDINARIO',
          )}
          image={img37}></ImageBlock>
      </section>
      <section className="my-12 md:px-12 text-left">
        <div className="px-12 flex flex-col">
          <div className="my-12 uppercase">
            <Text serif>{t('La sede di Prato')}</Text>
          </div>
          <Title size="hero">
            <Trans>
              VALORIZZARE LE IDEE <font className="text-secondary">INNOVATIVE</font>: PER QUANTO
              DIFFICILE CI SARA’ SEMPRE UNA{' '}
              <font className="text-secondary">SOLUZIONE POSSIBILE</font>
            </Trans>
          </Title>
        </div>
      </section>
      <section className="my-24">
        <TShapedBlock
          invert
          cta={t('Leggi la storia')}
          title={t(
            '"Per aver fatto della Ricerca dell’innovazione la costante dello sviluppo stilistico della propria azienda"',
          )}
          firstDesc={t(
            'I tempi sono cambiati, ma Lucchesi è ancora lì, nella stessa sede fondata da Guido Lucchesi, che ha accompagnato tutte le evoluzioni  della fabbrica e della moda dall’inizio del 900.',
          )}
          secondDesc={t(
            'Questo è il nostro segreto: una storia di innovazione che si rinnova di generazione in generazione, lasciando da oltre un secolo la nostra impronta sulle migliori collezioni di tutto il mondo.',
          )}
          image={img38}></TShapedBlock>
      </section>
      <section className="flex md:my-40 md:h-screen justify-center items-center">
        <StaticImage
          className="object-cover"
          src="../images/azienda/lucchesi_innovazione_annalisa.jpg">
        </StaticImage>
      </section>
    </Layout>
  );
};

export default InnovazionePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
